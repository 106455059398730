import { NetworkStatus, useQuery } from '@apollo/client';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Checkbox, Drawer, Form, Radio, Space } from 'antd';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { X } from 'phosphor-react';
import { useEffect, useMemo, useState } from 'react';
import {
  actionDimensionIds,
  MATOMO_ACTION,
  MATOMO_CATEGORY,
  MATOMO_NAME
} from '../../../../common/constants';
import useDeviceInfo from '../../../../common/useDeviceInfo';
import useNetworkType from '../../../../common/useNetworkInfo';
import { getUserCommonDimensions } from '../../../../common/utils';
import { GET_TOPICS } from '../../../../graphql/Queries';
import { useApp } from '../../../../providers/AppContext';
import InView from '../../../InView';
import Spinner from '../../../Spinner';
import { BREAKPOINTS } from '../../constants';

const initialFilter = {
  filter: {
    skip: 0,
    limit: 50
  }
};

export const SORT_BY = {
  CREATED_AT: 'createdAt',
  VIEW_COUNT: 'viewCount'
};

const SORT_OPTIONS = [
  {
    label: 'Newest',
    value: SORT_BY.CREATED_AT
  },
  { label: 'Views: High -> Low', value: SORT_BY.VIEW_COUNT }
];

export const DURATIONS = {
  LESS_THAN_3: 'LESS_THAN_3',
  MORE_THAN_3_AND_LESS_THAN_15: 'MORE_THAN_3_AND_LESS_THAN_15',
  MORE_THAN_15: 'MORE_THAN_15'
};

const DURATION_OPTIONS = [
  {
    label: 'Less than 3m',
    value: DURATIONS.LESS_THAN_3
  },
  {
    label: '3-15m',
    value: DURATIONS.MORE_THAN_3_AND_LESS_THAN_15
  },
  {
    label: 'Over 15m',
    value: DURATIONS.MORE_THAN_15
  }
];

const initialValues = {
  sortBy: SORT_BY.CREATED_AT,
  duration: '',
  topicIds: []
};

function FilterList({ onSubmit, onReset }) {
  const [form] = Form.useForm();
  const { data: sessionData } = useSession();
  const {
    state: { currentUser }
  } = useApp();
  const router = useRouter();
  const { trackEvent } = useMatomo();
  const device = useDeviceInfo();
  const networkType = useNetworkType();
  const { data, variables, fetchMore, networkStatus } = useQuery(GET_TOPICS, {
    variables: { ...initialFilter },
    context: {
      headers: {
        token: sessionData?.accessToken
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  const topicsOptions = useMemo(
    () =>
      data
        ? data?.topics?.topics?.map(({ id, name }) => ({
            label: name,
            value: id
          }))
        : [],
    [data]
  );

  const topicsCount = useMemo(() => (data ? data?.topics?.count : 0), [data]);
  const loading =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;
  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
  const hasMoreData = topicsCount > topicsOptions?.length;

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onReset={onReset}
      initialValues={initialValues}
    >
      <div className="filter-block">
        <div className="filter-body">
          <div className="filter-item">
            <div className="filter-title">Sort By</div>
            <Form.Item name="sortBy">
              <Radio.Group value="createdAt">
                <Space direction="vertical" size={6}>
                  {SORT_OPTIONS.map(({ label, value }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="filter-item">
            <div className="filter-title">Duration</div>
            <Form.Item name="duration">
              <Radio.Group>
                <Space direction="vertical" size={6}>
                  {DURATION_OPTIONS.map(({ label, value }) => (
                    <Radio key={value} value={value}>
                      {label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className="filter-item">
            <div className="filter-title">Topics</div>
            <div className="filter-checkbox-group">
              {loading ? (
                <Spinner className="mt-8" />
              ) : (
                <>
                  <Form.Item name="topicIds">
                    <Checkbox.Group>
                      {topicsOptions.map(({ label, value }) => (
                        <Checkbox value={value} key={value}>
                          {label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                  {isFetchingMore && <Spinner className="mt-8" />}
                  {hasMoreData && !isFetchingMore && !loading && (
                    <InView
                      onChange={({ inView }) => {
                        if (inView) {
                          fetchMore({
                            variables: {
                              ...variables,
                              filter: {
                                ...variables.filter,
                                skip: topicsOptions?.length
                              }
                            },
                            updateQuery: (prev, { fetchMoreResult }) => {
                              if (fetchMoreResult)
                                return {
                                  topics: {
                                    count: fetchMoreResult?.topics?.count,
                                    topics: [
                                      ...prev.topics.topics,
                                      ...fetchMoreResult.topics.topics
                                    ]
                                  }
                                };
                              return { ...prev };
                            }
                          });
                        }
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="filter-button-group">
          <Button
            htmlType="reset"
            className="cs-button"
            onClick={() =>
              trackEvent({
                category: MATOMO_CATEGORY.BUTTON,
                action: MATOMO_ACTION.CLICK,
                name: MATOMO_NAME.BUTTON_CLICK,
                customDimensions: [
                  {
                    id: actionDimensionIds.page_source,
                    value: router.asPath
                  },
                  {
                    id: actionDimensionIds.cta_title,
                    value: 'reset'
                  },
                  ...getUserCommonDimensions(currentUser, device, networkType)
                ]
              })
            }
          >
            Reset
          </Button>
          <Button
            htmlType="submit"
            className="cs-button"
            type="primary"
            onClick={() => {
              trackEvent({
                category: MATOMO_CATEGORY.BUTTON,
                action: MATOMO_ACTION.CLICK,
                name: MATOMO_NAME.BUTTON_CLICK,
                customDimensions: [
                  {
                    id: actionDimensionIds.page_source,
                    value: router.asPath
                  },
                  {
                    id: actionDimensionIds.cta_title,
                    value: 'Apply'
                  },
                  ...getUserCommonDimensions(currentUser, device, networkType)
                ]
              });
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Form>
  );
}
function Filter({ open, onClose, ...rest }) {
  const [isDesktop, setDesktop] = useState(null);
  useEffect(() => {
    setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      setDesktop(window.innerWidth > BREAKPOINTS.tablet);
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  }, []);
  return isDesktop ? (
    <FilterList {...rest} />
  ) : (
    <Drawer
      className="cs-ant-drawer filter-drawer"
      title="Filter"
      width={375}
      placement="right"
      closable
      onClose={onClose}
      open={open}
      closeIcon={<X size={24} weight="bold" />}
    >
      <FilterList {...rest} />
    </Drawer>
  );
}

export default Filter;
